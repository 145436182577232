import * as React from 'react';
import { withLayout } from '../../components/layout.tsx';
import { Embed } from 'semantic-ui-react';
import { H1, H2 } from '../../components/mdx_blocks/header';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { getSrc, GatsbyImage } from 'gatsby-plugin-image';

function UseCases() {
  const images = useStaticQuery(graphql`
    query {
      monterey_annotated: file(
        relativePath: {
          eq: "applications/video_previews/monterey_annotated.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 1080, placeholder: TRACED_SVG)
        }
      }
      reinforced_concrete: file(
        relativePath: { eq: "applications/reinforced_concrete.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 1200, placeholder: TRACED_SVG)
        }
      }
    }
  `);

  return (
    <>
      <H1>Use cases</H1>
      This page demonstrates a few potential use cases of Salvus, our software
      suite and end-to-end solution for all waveform modeling and inversion
      needs. Every step for these use cases was performed fully with Salvus;
      this includes problem setup, meshing, job submissions, waveform
      simulations, data management and numerical optimization routines.{' '}
      <p>
        If you want to learn more, head over to{' '}
        <Link to="/docs">our documentation</Link> for more technical
        explanations, in-depth tutorials and API descriptions.
      </p>
      <H2>3D Simulations</H2>
      <p>
        Salvus excels in the large-scale computation of fully 3-D wavefields in
        realistic and complicated environments. In this use case, Salvus was
        used to investigate the character of vibrational strain signals recorded
        along an ocean bottom fiber-optic cable. High resolution surface and
        seabed topography, along with the accurate coupling of both viscoelastic
        and acoustic wavefields, are automatically included using our simple
        Python-based interface. Simulations such as these help researchers
        investigate new and exciting applications of distributed acoustic
        sensing (DAS).
      </p>
      <p>
        The following illustration highlights a few of the complexities Salvus
        handles - click on it to see a video of these waves propagating. All
        this readily scales from running on laptops, to GPU clusters all the way
        to big machines with thousands of cores. The simulation rendered below
        took less than a few minutes to run on a single GPU.
      </p>
      <Embed
        id="465477132"
        source="vimeo"
        autoplay={false}
        placeholder="https://vumbnail.com/465477132.jpg"
        iframe={{
          allowFullScreen: true,
        }}
      />
      <H2>Nondestructive Evaluation</H2>
      <p>
        Salvus can efficiently model ultrasound propagation in materials
        relevant for structural health monitoring. With the included
        Python-based toolbox for digitial twin creation, this opens up the
        application wavefield-based optimal experimental design and
        full-waveform inversion to applications in nondestructive evaluation.
        Below, we see a fully 3-D elastic ultrasound wavefield propagating
        through reinforced concrete with rebar. Simulations like this one can be
        run in a matter of seconds on a single consumer-grade workstation.
      </p>
      <GatsbyImage
        image={images.reinforced_concrete.childImageSharp.gatsbyImageData}
        alt="Waves in reinforced concrete."
      />
      <H2>Pipeline Monitoring</H2>
      <p>
        Salvus efficiently computes wavefields propagating in engineering
        components with complex geometries. In this application, we see the
        scattered wavefield emitted by an ultrasound transducer array and
        interacting with a critical thickness variation of a pipeline. By
        examining the character of this scattered wavefield, and combining it
        with our full-waveform inversion functionality, Salvus is opening up
        exciting new paths towards corrosion mapping and pipeline monitoring.
      </p>
      <Embed
        id="465480798"
        source="vimeo"
        autoplay={false}
        placeholder="https://vumbnail.com/465480798.jpg"
        iframe={{
          allowFullScreen: true,
        }}
      />
      <H2>Borehole Acoustics</H2>
      <p>
        Salvus simulates multiple wave propagation physics, including coupled
        acoustic / viscoelastic waves propagating through air or water-filled
        boreholes in 2- and 3-D. These simulations provide a quantitative
        evaluation of subsurface materials and structures, and assist in
        characterizing the imprint of thin fractures.
      </p>
      <Embed
        id="465477878"
        source="vimeo"
        autoplay={false}
        placeholder="https://vumbnail.com/465477878.jpg"
        iframe={{
          allowFullScreen: true,
        }}
      />
      <H2>Full-Waveform Inversion</H2>
      <p>
        Salvus provides a robust and extensible interface to a purpose-built
        nonlinear optimization library for full-waveform inversion. By
        leveraging adjoint functionality, checkpointing and GPU accelerators,
        Salvus efficiently computes sensitivities with respect to a variety of
        model parameters. The inversion framework provides powerful workflows,
        data management and remote job execution while offering the flexibility
        to interface with custom misfit and processing functions. Our portfolio
        of use cases includes subsurface exploration, nuclear explosion
        monitoring, continental-scale seismology, nondestructive testing, and
        medical imaging.
      </p>
      <Embed
        id="465482262"
        source="vimeo"
        autoplay={false}
        placeholder="https://vumbnail.com/465482262.jpg"
        iframe={{
          allowFullScreen: true,
        }}
      />
      <H2>Extra-planetary Imaging</H2>
      <p>
        The geometrical flexibility of Salvus brings seismic imaging techniques
        to planets, asteroids and other extraterrestrial bodies. Accurate
        modeling of surface topography and solid/fluid coupling in the interior
        are crucial to understand the seismicity of these objects. For instance,
        Salvus is used by NASA’s Insight mission to run large-scale simulations
        for various scenarios of the interior structure of Mars.
      </p>
      <Embed
        id="465482106"
        source="vimeo"
        autoplay={false}
        placeholder="https://vumbnail.com/465482106.jpg"
        iframe={{
          allowFullScreen: true,
        }}
      />
      <H2>Seismology</H2>
      <p>
        Seismic waves can be used to study many aspects of the Earth's crust,
        mantle, and core. Salvus can mesh, simulate, and invert on all scales
        ranging from small scale basin level studies to the full globe. This
        naturally includes complexities like oceans, as well as internal and
        external topographies.
      </p>
      <Embed
        id="467201570"
        source="vimeo"
        autoplay={false}
        placeholder="https://vumbnail.com/467201570.jpg"
        iframe={{
          allowFullScreen: true,
        }}
      />
    </>
  );
}

export default withLayout(UseCases);
